
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import VideoDialog from '@/components/videoDialog.vue';

@Component({
    name: "Index",
    components: {
        ContentTitle,
        SingleHeader,
        SingleFooter,
        VideoDialog,
    },
})
export default class Index extends Vue {
    private cloudDomain: boolean =
        document.domain.indexOf('.morewis.com') > -1 ||
        document.domain.indexOf('xwww.morewiscloud.com') > -1 ||
        document.domain.indexOf('lwww.morewiscloud.com') > -1 ||
        document.domain.indexOf('dwww.morewiscloud.com') > -1;
    private contentTitle: string = "首页";
    private baseUrlIframe = process.env.VUE_APP_URL;
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private useInfo: any = {}; // 用户信息
    private scrollBoxTime: any = null;
    private index: number = 0;
    private tokenExpiresTime: any = localStorage.getItem('id_token_expiresTime');
    private exitFlag = false; // 控制videoDialog打开关闭的flag
    private videoSrc = ''; // 传给videoDialog的播放源
    private activeName = 'first';
    private activePart = 'partner';
    private activeImgs: any = {
        partner: [],
        saas: [],
        customer: [],
    };
    private imgArr = [
        require('@/assets/images/index/index/down.png'),
        require('@/assets/images/index/index/up.png'),
        require('@/assets/images/index/index/OEE.png'),
        require('@/assets/images/index/index/device.png'),
        require('@/assets/images/index/index/charts.png'),
        require('@/assets/images/index/index/plan.png'),
        require('@/assets/images/index/index/1.png'),
        require('@/assets/images/index/index/2.png'),
        require('@/assets/images/index/index/3.png'),
        require('@/assets/images/index/index/4.png'),
        require('@/assets/images/index/index/5.png'),
        require('@/assets/images/index/play.png'),
        require('@/assets/images/index/index/k1.png'),
        require('@/assets/images/index/index/k2.png'),
        require('@/assets/images/index/index/k3.png'),
        require('@/assets/images/index/index/k4.png'),
        require('@/assets/images/index/index/partner-w.png'),
        require('@/assets/images/index/index/partner.png'),
        require('@/assets/images/index/index/Saas-w.png'),
        require('@/assets/images/index/index/Saas.png'),
        require('@/assets/images/index/index/customer-w.png'),
        require('@/assets/images/index/index/customer.png'),
        require('@/assets/images/index/index/kbg.png'),
    ];
    private gifArr = [
        require('@/assets/images/index/index/WisIDE-Web.gif'),
        require('@/assets/images/index/index/WisIDE-AB.gif'),
        require('@/assets/images/index/index/WisIDE-Link.gif'),
        require('@/assets/images/index/index/WisIDE-APP.gif'),
        require('@/assets/images/index/index/WisIDE-3D.gif'),
        require('@/assets/images/index/index/WisIDE-BI.gif'),
    ];
    private nums: any = {
        num1: 1,
        num2: 1,
        num3: 1,
        num4: 1,
    };

    private mounted() {
        window.addEventListener('resize', this.eventListener, false);
        this.getCustomerImage();
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private beforeDestroy() {
        window.removeEventListener('resize', this.eventListener, false);
    }
    private async openIndexOrRegister() {
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        this.useInfo = this.$store.state.userInfo;
        if (await this.$isLogin()) {
            if (this.useInfo.userType === 1) {
                if (this.cloudDomain) {
                    this.$router.push("/person").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/person';
                }
            } else if (this.useInfo.userType === 2) {
                if (this.cloudDomain) {
                    this.$router.push("/company").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/company';
                }
            } else {
                if (this.cloudDomain) {
                    this.$router.push("/company").catch((err) => err);
                } else {
                    window.location.href = this.baseUrlIframe + baseUrl + '/company';
                }
            }
        } else {
            if (this.cloudDomain) {
                this.$router.push("/register").catch((err) => err);
            } else {
                window.location.href = this.baseUrlIframe + baseUrl + '/register';
            }
        }
    }
    private created() {
        if (this.$route.fullPath.indexOf('?url=') > -1 && this.cloudDomain) {
            console.log('this.$route.fullPath', this.$route.fullPath);
            let urlParam = this.$route.fullPath.split('?url=')[1];
            urlParam = urlParam.replace('&', '?');
            const urlArr = urlParam.split('?');
            this.$router.push({
                name: "OrderConfirm",
                params: { url: unescape(urlArr[0]), data: urlArr[1] || ''}}).catch((err) => err);
        }
        this.$nextTick(() => {
            this.eventListener();
        });
        this.numDynamic('num1', 65);
        this.numDynamic('num2', 50);
        this.numDynamic('num3', 70);
        this.numDynamic('num4', 80);
    }
    private getCustomerImage() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/customer-images').then((res: any) => {
            res.forEach((item: any) => {
                if (item.type === 0) {
                    this.activeImgs.partner = this.setActiveImgs(item.customerImageList);
                } else if (item.type === 1) {
                    this.activeImgs.customer = this.setActiveImgs(item.customerImageList);
                } else if (item.type === 2) {
                    this.activeImgs.saas = this.setActiveImgs(item.customerImageList);
                }
            });
        });
    }
    private eventListener() {
        const even: any = document.getElementsByClassName('par-item is-active')[0];
        this.handlePartnerClick(this.activePart, even);
    }
    private numDynamic(key: string, value: number, speed: number = 18) {
        if (value > 0) {
            let num = 1;
            const t = setInterval(() => {
                num ++;
                this.nums[key] = num;
                if (num === value) {
                    clearInterval(t);
                }
            }, speed);
        } else {
            this.nums[key] = value;
        }
    }
    private rollScroll(event: any) {
        // chrome、ie使用的wheelDelta，火狐使用detail
        const scrollVal = event.wheelDelta || event.detail;
        // 节流
        if (this.scrollBoxTime) {
            clearTimeout(this.scrollBoxTime);
        }
        this.scrollBoxTime = setTimeout(() => {
            const carousel =  this.$refs.carousel as any;
            scrollVal > 0 ? carousel.prev() : carousel.next();
        }, 300);
    }
    private setActiveImgs(imgList: any = []) {
        const imgs = imgList.map((item: any) => `${this.baseUrl}${item.image}`);
        const arr = imgs.slice(0, 15);
        return arr;
    }
    private handlePartnerClick(tab: any, event: any) {
        this.activePart = tab;
        // this.setActiveImgs(tab);
        const bar: any = document.getElementsByClassName('bgfloat')[0];
        const curEvent = event.target ? event.target : event;
        let parentElement: any = null;
        // console.log(tab, event, curEvent);
        if (curEvent.dataset.click) {
            parentElement = curEvent.parentElement;
        } else {
            parentElement = event.path.find((item: any) => item.nodeName === "LI");
        }
        const translateX = parentElement.offsetLeft;
        this.$nextTick(() => {
            bar.style.transform = `translateX(${translateX}px)`;
        });
    }
    private handleTabClick(tab: string, event: any) {
        this.activeName = tab;
        // console.log(tab, event);
    }
    private handleNext() {
        // 节流
        if (this.scrollBoxTime) {
            clearTimeout(this.scrollBoxTime);
        }
        this.scrollBoxTime = setTimeout(() => {
            const carousel =  this.$refs.carousel as any;
            carousel.next();
        }, 300);
    }
    private openVideo(videoSrc: string) {
        this.videoSrc = videoSrc;
        this.exitFlag = true;
    }
    // 接收关闭弹窗传回来的值
    private propCloseMsg(num: number) {
        this.exitFlag = false;
    }
    private onmouseenter(event: any) {
        // console.log(event);
        const target = event.target;
        const parentNode = target.parentNode;
        const prevNode = parentNode.previousSibling;
        const nextNode = parentNode.nextSibling;
        // target.style.color = '#333';
        parentNode.style.margin = '0 10px';
        target.style.transform = 'scale(1.18)';
        if (nextNode) {
            nextNode.firstChild.style.transform = 'scale(1.1)';
        }
        if (prevNode) {
            prevNode.firstChild.style.transform = 'scale(1.1)';
        }
    }
    private onmouseleave(event: any) {
        // console.log(event);
        const target = event.target;
        const parentNode = target.parentNode;
        const prevNode = parentNode.previousSibling;
        const nextNode = parentNode.nextSibling;
        // target.style.color = '#999';
        parentNode.style.margin = '0';
        target.style.transform = 'scale(1)';
        if (nextNode) {
            nextNode.firstChild.style.transform = 'scale(1)';
        }
        if (prevNode) {
            prevNode.firstChild.style.transform = 'scale(1)';
        }
    }
    private navLink(type: number) {
        if (type === 4) {
            // window.location.href = '/mall/index.htm';
            this.$router.push('/mall');
        }
    }
    private gotoNoviceGuide() {
        const baseUrlIframe = process.env.VUE_APP_URL;
        window.open(baseUrlIframe + '/doc/index.htm');
    }
}
